<template>
  <v-container
    fluid
    tag="section"
    style="z-index:9999;"
    :class="{negative}"
  >
    <v-tabs
      v-model="tab"
      grow
      show-arrows
      :style="`position:relative;font-size:${zoom}%;`"
      id="liveview"
      :dark="negative"
    >
      <v-tab
        v-for="t in tabs"
        :key="t.key"
        :href="`#${t.href}`"
        @click="stopauto"
      >
        {{ t.title }}
      </v-tab>
      <v-tab
        v-for="t in menu"
        :key="t.key"
        :href="`#${t.href}`"
        style="display:none;"
      >
        {{ t.title }}
      </v-tab>

      <v-menu
        v-if="menu.length"
        bottom
        left
        attach="#liveview"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="align-self-center mr-4"
            v-bind="attrs"
            v-on="on"
          >
            mehr...
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="t in menu"
            :key="t.key"
            @click="tab = t.href"
          >
            <template #default>
              <span
                :class="{'red--text': t.href === tab}"
              >
                {{ t.title }}
              </span>
            </template>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip v-if="timeout !== null">
        <template v-slot:activator="{on}">
          <v-btn
            v-on="on"
            text
            fab
            small
            @click="stopauto"
          >
            <i class="far fa-bolt-auto" style="font-size: 20px;" />
          </v-btn>
        </template>
        <span>
          Automatik beenden
        </span>
      </v-tooltip>
      <v-tooltip v-else>
        <template v-slot:activator="{on}">
          <v-btn
            v-on="on"
            text
            fab
            small
            @click="startauto"
          >
            <span class="fa-stack fa-2x">
            <i class="far fa-bolt-auto fa-stack-1x" style="font-size:15px;" />
            <i class="far fa-ban fa-stack-1x" style="font-size:28px;color:lightgray;line-height:40px;" />
          </span>
          </v-btn>
        </template>
        <span>
            Automatik starten
        </span>
      </v-tooltip>

      <v-btn
        text
        fab
        small
        @click="negative = !negative"
      >
        <v-icon>
          far fa-circle-half-stroke
        </v-icon>
      </v-btn>

      <v-btn
        text
        fab
        small
        @click="zoom += 25"
      >
        <v-icon>
          far fa-magnifying-glass-plus
        </v-icon>
      </v-btn>

      <v-btn
        text
        fab
        small
        @click="zoom -= 25"
      >
        <v-icon>
          far fa-magnifying-glass-minus
        </v-icon>
      </v-btn>

      <v-btn
        text
        fab
        small
        @click="fullscreen"
      >
        <v-icon v-if="!isFullscreen">
          far fa-expand-wide
        </v-icon>
        <v-icon v-else>
          far fa-compress-wide
        </v-icon>
      </v-btn>

      <v-tab-item
        v-for="t in [...tabs,...menu]"
        :key="t.key"
        :value="t.href"
      >
        <geraet_wk_4aus5 v-if="t.route === 'geraet/wk_4aus5'" :id="t.id" :did="t.did" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />
        <uebersicht_wk_4aus5 v-else-if="t.route === 'uebersicht/wk_4aus5'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />
        <finished_wk_4aus5 v-else-if="t.route === 'finished/wk_4aus5'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />

        <geraet_wk_score4 v-else-if="t.route === 'geraet/wk_score4'" :id="t.id" :did="t.did" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />
        <uebersicht_wk_score4 v-else-if="t.route === 'uebersicht/wk_score4'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />
        <finished_wk_score4 v-else-if="t.route === 'finished/wk_score4'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />

        <finished_lf v-else-if="t.route === 'finished/lf'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />

        <lf_startgeraet v-else-if="t.route === 'lf/startgeraet'" :es="Events" :df="DisciplineFind" />
        <lf_turner v-else-if="t.route === 'lf/turner'" :es="Events" :rs="Results || []" :df="DisciplineFind" />
        <geraet_lf v-else-if="t.route === 'geraet/lf'" :es="Events" :rs="Results || []" :df="DisciplineFind" :did="t.did" />
        <uebersicht_lf v-else-if="t.route === 'uebersicht/lf'" :id="t.id" :e="Events[t.id]" :r="Results[t.id] || []" :df="DisciplineFind" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint-disable */
import { updateQuery, useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../plugins/calc'
import gql from 'graphql-tag'
import { dquery, query, resultquery } from '@/views/components/stb_m_2021/content/view/query'
import Vue from 'vue'

const time = 15000

export default {
  name: 'liveview',

  components: {
    geraet_wk_4aus5: () => import('./view/geraet/wk_4aus5'),
    uebersicht_wk_4aus5: () => import('./view/uebersicht/wk_4aus5'),
    finished_wk_4aus5: () => import('./view/finished/wk_4aus5'),
    geraet_wk_score4: () => import('./view/geraet/wk_score4'),
    uebersicht_wk_score4: () => import('./view/uebersicht/wk_score4'),
    finished_wk_score4: () => import('./view/finished/wk_score4'),
    finished_lf: () => import('./view/finished/lf'),
    lf_startgeraet: () => import('./view/lf/startgeraet'),
    lf_turner: () => import('./view/lf/turner'),
    geraet_lf: () => import('./view/geraet/lf'),
    uebersicht_lf: () => import('./view/uebersicht/lf')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc(props, context)
    }
  },

  props: {
    ids: {
      type: String,
      required: true
    }
  },

  data: () => ({
    DisciplineFind: [],
    Events: {},
    Results: {},
    tab: 0,
    isFullscreen: false,
    timeout: null,
    negative: false,
    zoom: 100,
  }),

  computed: {
    id () {
      return this.ids.split(',')
    },
    tabs () {
      return this.getTabs(this.Events, this.Results, this.DisciplineFind).filter(t => !t.hidden).map(t => ({
        ...t,
        key: `${t.route}/${t.id}/${t.did}`,
        href: `${t.route}/${t.id}/${t.did}`.replaceAll('/','-')
      })).filter((a, i, arr) => !arr.find((b, j) => b.key === a.key && i > j))
    },
    menu () {
      return this.getTabs(this.Events, this.Results, this.DisciplineFind).filter(t => t.hidden).map(t => ({
        ...t,
        key: `${t.route}/${t.id}/menue/${t.did}`,
        href: `${t.route}/${t.id}/menue/${t.did}`.replaceAll('/','-')
      })).filter((a, i, arr) => !arr.find((b, j) => b.key === a.key && i > j))
    }
  },

  methods: {
    fullscreen () {
      this.$fullscreen.toggle(this.$el.querySelector('#liveview'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.isFullscreen = fullscreen
    },
    startauto () {
      this.stopauto()

      const i = this.tabs.findIndex(i => i.href === this.tab)
      const next = this.tabs[(i + 1) % this.tabs.length]

      this.tab = next.href

      this.timeout = setTimeout(this.wechsler, next.time ? next.time : time)
    },
    stopauto () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
      }

      this.timeout = null
    },
    wechsler () {
      this.stopauto()

      const i = this.tabs.findIndex(i => i.href === this.tab)
      if (i === -1) return

      const next = this.tabs[(i + 1) % this.tabs.length]

      this.tab = next.href

      this.timeout = setTimeout(this.wechsler, next.time ? next.time : time)
    }
  },

  created () {
    this.id.forEach(id => {
      this.$apollo.query({
        query: gql`query($id: UUID!) {
          Event(id: $id) { ${query} }
        }`,
        variables: {
          id
        }
      }).then(({ data }) => {
        Vue.set(this.Events, id, data.Event)
      })
      this.$apollo.subscribe({
        query: gql`subscription($id: UUID!) {
          EventUpdate(id: $id) { ${query} }
        }`,
        variables: {
          id
        }
      }).subscribe({
        next: ({ data }) => {
          const status = {
            auto: !!this.timeout,
            alt: this.Events[data.EventUpdate._id].status,
            neu: data.EventUpdate.status
          }
          Vue.set(this.Events, data.EventUpdate._id, data.EventUpdate)

          if (status.auto && status.alt !== status.neu) {
            this.startauto()
          }
        }
      })

      this.$apollo.query({
        query: gql`query($type: ResultType!, $event: UUID!) {
          ResultFind(type: $type, event: $event) { ${resultquery} }
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).then(({ data }) => {
        Vue.set(this.Results, id, data.ResultFind)
      })
      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultCreate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: ({ data }) => {
          this.Results[id].push(data.ResultCreate)
        }
      })
      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultUpdate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: ({ data }) => {
          const i = this.Results[id].findIndex(r => r._id === data.ResultUpdate._id)
          Vue.set(this.Results[id], i, data.ResultUpdate)
        }
      })
      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultDelete(type: $type, event: $event)
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: ({ data }) => {
          const i = this.Results[id].findIndex(r => r._id === data.ResultDelete)
          Vue.delete(this.Results[id], i)
        }
      })
    })

    this.timeout = setTimeout(this.wechsler, time)
  },

  apollo: {
    DisciplineFind: {
      query: gql`query {
        DisciplineFind { ${dquery} }
      }`,
      fetchPolicy: 'cache-first',
      subscribeToMore: {
        document: gql`subscription {
          DisciplineCreate { ${dquery} }
        }`,
        updateQuery: updateQuery('DisciplineFind', 'DisciplineCreate')
      }
    }
  }
}
</script>

<style>
#liveview {
  background: white;
}

.negative, .negative #liveview, .negative #liveview .v-window-item {
  background: black;
}

@media(max-width: 600px) {
  .container {
    font-size: 60%;
  }

  .container .v-tab {
    font-size: 80%;
    padding: 4px !important;
    min-width: 0px !important;
  }
}

#liveview table {
  width: 100%;
  border-spacing: 0px;
  font-size: 200%;
}

#liveview tr td {
}

#liveview tr td.heim {
  text-align: right
}

#liveview tr.head {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.8);
  color: white;
}

#liveview table.score tr.head td.heim {
  background:red;
}

#liveview table.score tr.head td.gast {
  background:blue;
}

#liveview table td {
  padding: 0px 4px;
}

#liveview tr.even td {
  background: rgba(0, 0, 0, 0.1);
}

.negative #liveview tr {
  background: black;
  color: white;
}

.negative #liveview tr.even td {
  background: rgba(255, 255, 255, 0.25);
}

#liveview .name {
  font-weight: bold;
}

#liveview .endnote {
  font-weight: bold;
  font-size: 150%;
}

#liveview .hidden {
  font-size: 0;
}

#liveview tr.ergebnis td {
  font-weight: bold;
}

#liveview tr.ergebnis td.ergebnis {
}
</style>
